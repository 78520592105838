.SliderContainer {
  position: fixed;
  width: 200px;
  left: 50%;
  bottom: 50px;
  // transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 999;
}

.MuiSlider-track {
  border-radius: 0px !important;
}

.MuiSlider-thumb {
  border-radius: 0px !important;
  width: 4px !important;
  margin-left: 0px !important;
}

.MuiSlider-colorPrimary {
  color: white !important;
}
