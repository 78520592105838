.SimImage {
  position: relative;
  cursor: pointer;
  position: relative;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 0px 150px 0 rgba(0, 0, 0, 0.795);
    opacity: 0;
    transition: opacity 800ms;
  }

  &:hover::before {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
    z-index: 1;
  }
}

.DialogGrid {
  z-index: 999999 !important;
  margin: 0 auto !important;
  padding-left: 10% !important;
  padding-right: 10% !important;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: white;

  .AboutElements {
    position: relative;
    padding-left: 50px;
    font-family: 'VCR', sans-serif !important;
    div {
      position: absolute;
      bottom: 0px;
    }
    h1 {
      font-size: 26px;
      margin: 0 auto;
      text-shadow: 0px 0px 20px #ffffff50;
    }

    p {
      margin-top: 10px;
      letter-spacing: 0.5px;
      font-size: 12px;
    }
  }
}

.CloseIcon {
  cursor: pointer;
  width: 15px;
  position: absolute;
  top: 7%;
  right: 10%;
}

.MuiPaper-root,
.MuiDialog-paper,
.MuiDialog-paperScrollPaper {
  background-color: black !important;
}

.OpenseaLink {
  bottom: 10px;
  position: absolute;
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  text-decoration: none;

  .LinkImg {
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    align-items: center;

    img {
      width: 20px;
    }
  }

  .View {
    margin-left: 10px;
    margin-top: 5px;

    a {
      color: #888d9b;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .DialogGrid {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .AboutElements {
    margin-top: 35px !important;
    p {
      font-size: 10px !important;
    }
    h1 {
      font-size: 20px !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  .DialogGrid {
    padding-left: 0% !important;
    padding-right: 0% !important;
    width: 80% !important;
    margin: 0 auto;

    // position: absolute;

    .AboutElements {
      margin-top: 50px;
    }
  }

  .MuiGrid-spacing-xs-3 {
    width: calc(100%) !important;
    margin: -12px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
