.App {
  height: 100vh;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .Time {
    font-size: 130px !important;
  }
}

@media only screen and (max-width: 800px) {
  .Time {
    font-size: 100px !important;
  }

  .Unit {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 550px) {
  .Time {
    font-size: 42px !important;
  }

  .Unit {
    font-size: 10px !important;
  }
}

.Overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.568);
  z-index: 999999999;
  color: white;

  .EnterContainer {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1100px;
  }

  p {
    margin: 0 auto;
    height: 100%;
    text-shadow: 0px 0px 16px #ffffffda;
    letter-spacing: 0.5px;
    transition: 0.5s;
    // font-size: 100px;
  }

  .Time {
    font-size: 180px;
  }

  .Unit {
    font-size: 22px;
  }

  .ExternalLinks {
    bottom: 50px !important;
    text-align: center;
    width: 100%;
    position: absolute;
  }

  .ExternalOpenseaLink {
    // width: 100% !important;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;

    .DividerBar {
      color: #888d9b;
      font-size: 19px !important;
      padding: 5px;
    }

    a {
      text-decoration: none;
      color: #888d9b;

      p {
        font-size: 18px !important;
        text-shadow: none;
        padding: 5px;
        &:hover {
          text-shadow: 0px 0px 20px #bebebeda !important;
        }
      }
    }
  }

  .CollectThemAll {
    margin-top: 10px;
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    -ms-animation: blink 1s linear infinite;
    -o-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
  }

  img {
    margin: 0px;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  50.01% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.SliderContainer {
  // position: fixed;
  // width: 300px;
  // left: 50%;
  // bottom: 50px;
  // transform: translate(-50%, -50%);
  // margin: 0 auto;
  z-index: 999;
  opacity: 0;
  transition: 0.5s all;
  cursor: pointer;
}

.Audio {
  width: 15px;
  right: 50px;
  bottom: 13px;
  // position: fixed;
}

.SliderApp {
  position: fixed;
  bottom: 20px;
  width: 50%;
  max-width: 200px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.AudioApp {
  position: fixed;
  bottom: 25px;
  width: 30px;
  left: 95%;
}

.MouseMove {
  opacity: 0.5;
}

@media only screen and (max-width: 400px) {
  .Overlay {
    p {
      font-size: 26px;
    }
  }

  .SliderApp {
    width: 60%;
  }

  .Audio {
    // width: 15px;
    // right: 15px;
    // bottom: 63px !important;
  }

  .AudioApp {
    left: 80%;
  }
}

@media only screen and (max-width: 700px) {
  .AudioApp {
    left: 90%;
    bottom: 27px !important;
  }
}

@media only screen and (max-width: 550px) {
  // .EnterContainer {
  //   position: absolute;
  //   width: 100vw !important;
  //   height: 100vh !important;
  //   top: 50%;
  //   left: 50%;
  //   margin-left: -50vw !important; /* margin is -0.5 * dimension */
  //   margin-top: -20vh !important;

  //   p {
  //     text-shadow: 0px 0px 20px #ffffffda;
  //     letter-spacing: 0.5px;
  //     transition: 0.5s;
  //     font-size: 24px;
  //     margin-bottom: 10px;
  //   }

  //   img {
  //     width: 70%;
  //   }
  // }
}
