@font-face {
  font-family: "VCR";
  font-style: normal;
  src: url("../src/fonts/vcr.ttf");
}

* {
  font-family: "VCR", sans-serif !important;
}

body {
  height: 100vh;
  background-color: black;
  font-family: "VCR", sans-serif !important;
  margin: 0;

  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
